import { reject, set, sortBy, concat } from 'lodash/fp'

/**
 * @typedef HideTabsConfig
 * @type {{layout: string[], data: string[], conditional: string[], addons: string[], display: string[], api: string[], logic: string[], validation: string[]}}
 */
const DEFAULT_HIDE_CONFIG = {
    display: [
        'labelPosition',
        'labelWidth',
        'labelMargin',
        'tooltip',
        'customClass',
        'autofocus',
        'tableView',
        'modalEdit',
    ],
    data: [
        'multiple',
        'persistent',
        'protected',
        'dbIndex',
        'encrypted',
        'redrawOn',
        'clearOnHide',
        'customDefaultValuePanel',
        'calculateValuePanel',
        'calculateServer',
        'allowCalculateOverride',
    ],
    validation: [
        'unique',
        'validate.customMessage',
        'custom-validation-js',
        'json-validation-json',
        'errors',
    ],
    api: [],
    conditional: ['customConditionalPanel'],
    logic: ['*'],
    layout: ['*'],
    addons: ['*'],
}

/**
 * Baseline Configurations for hiding tabs to keep things DRY
 *
 * @type {{customComp: {HideTabsConfig}, formioComp: {HideTabsConfig}}}
 */
export const omitTabsBaseConfig = {
    customComp: DEFAULT_HIDE_CONFIG,
    formioComp: {
        ...DEFAULT_HIDE_CONFIG,
        display: [
            'labelPosition',
            'labelWidth',
            'labelMargin',
            'tooltip',
            'customClass',
            'autofocus',
            'tableView',
            'modalEdit',
        ],
        data: [
            'persistent',
            'encrypted',
            'redrawOn',
            'clearOnHide',
            'customDefaultValuePanel',
            'calculateValuePanel',
            'calculateServer',
            'allowCalculateOverride',
        ],
    },
}

/**
 * Omit Formio Tabs from Edit Menu
 *
 * @description Creates an editForm function for a component that will remove any unwanted tabs
 * or items from tabs
 *
 * @example
 * ```
 *   static editForm = omitFormioTabs(settingsForm, {
 *       display: ['label', 'labelPosition'],
 *       conditional: ['*'],
 *   })
 * ```
 * @param settingsFn {function}
 * @param tabsToOmit {HideTabsConfig}
 * @returns {function(*): *} a function to create this components edit menu in form builder
 */
function omitFormioTabs(settingsFn, tabsToOmit = {}) {
    return extend => {
        const rv = settingsFn(extend)
        const [tabs, ...rest] = sortBy(x => x.key !== 'tabs', rv.components)

        const updatedTabComponents = (tabs.components || []).reduce((acc, next) => {
            const omitList = tabsToOmit[next.key] || []
            if (omitList.includes('*')) {
                return acc
            }
            return concat(acc, {
                ...next,
                components: reject(({ key }) => omitList.includes(key), next.components),
            })
        }, [])

        return set('components', [set('components', updatedTabComponents, tabs), ...rest], rv)
    }
}

export default omitFormioTabs
