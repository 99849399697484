import { withAuthenticationRequired } from '@auth0/auth0-react'

import FormsRouter from './FormsRouter'

const SecuredApp = () => {
    return <FormsRouter hasAccessToFormBuilder />
}

export default withAuthenticationRequired(SecuredApp, {
    onRedirecting: () => <>Redirecting you to login</>,
})
