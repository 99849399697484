import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material'
import Edit from '@mui/icons-material/Edit'
import Preview from '@mui/icons-material/Preview'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { FormApiResponse, FormDefinitionApiResponse } from 'api/forms-api'
import { validateFormDefinition } from 'pages/FormBuilder/formDefinitionValidation'

type FormBuilderHeaderProps = {
    form: FormApiResponse
    onOpenDefinitionEditor: () => void
    onSave: () => void
    onPreview: () => void
    latestFormDefintion: null | FormDefinitionApiResponse
    builderFormDefinition: FormDefinitionApiResponse
}

// formTitleVariant gets a suitable heading variant to display title
function formTitleVariant(formUid = ''): 'h4' | 'h5' | 'h6' {
    const { length } = formUid
    if (length > 60) return 'h6'
    if (length > 30) return 'h5'
    return 'h4'
}

const FormBuilderHeader = ({
    form,
    onOpenDefinitionEditor,
    onSave,
    onPreview,
    latestFormDefintion,
    builderFormDefinition,
}: FormBuilderHeaderProps): JSX.Element => {
    const definitionIsNotCurrent =
        !!latestFormDefintion && form.current_form_definition_id != latestFormDefintion?.id
    const validationErrors = validateFormDefinition(builderFormDefinition)

    return (
        <Box
            sx={{
                m: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Typography
                    variant={formTitleVariant(form.uid)}
                    sx={{ mr: 1, width: 'calc(100% - 625px)', wordBreak: 'break-all' }}
                >
                    {form.title}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ mr: 1 }}
                        onClick={onPreview}
                        startIcon={<Preview />}
                        data-qa="preview"
                    >
                        Preview
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{ mr: 1, backgroundColor: '#ECFDF5' }}
                        onClick={onSave}
                        data-qa="save-form"
                    >
                        Save
                    </Button>
                    <Button
                        data-qa={'edit-form-json'}
                        onClick={onOpenDefinitionEditor}
                        startIcon={<Edit fontSize="small" />}
                        variant="outlined"
                    >
                        Edit form json
                    </Button>
                </Box>
            </Box>
            <Box>
                <Typography variant="subtitle1">
                    <strong>Description:</strong> {form.description}
                </Typography>
                {definitionIsNotCurrent ? (
                    <Alert severity="warning">
                        <p>
                            You are editing a new version of this Form. It is not being assigned to
                            members yet.
                        </p>
                        <p>
                            If this version ({latestFormDefintion?.version}) is ready to be assigned
                            to members, contact the tech team for help.
                        </p>
                    </Alert>
                ) : null}
                {Object.keys(validationErrors).length ? (
                    <Alert severity="warning" sx={{ '& .MuiAlert-message': { width: '90%' } }}>
                        Errors
                        <Grid container mt={1}>
                            {Object.keys(validationErrors).map(key => (
                                <Grid item container key={key} md={12}>
                                    <Grid item md={12} mb={1}>
                                        <strong>{key}</strong>
                                        {validationErrors[key].map((message, i) => (
                                            <Grid item key={i} md={12}>
                                                {message}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Alert>
                ) : null}
            </Box>
            <Accordion>
                <AccordionSummary
                    sx={{ '& .MuiAccordionSummary-content': { m: 0 } }}
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Developer Info</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle2" mr={1}>
                        <strong>Form ID:</strong> {form.id}
                    </Typography>
                    <Typography variant="subtitle2" mr={1}>
                        <strong>Form UID:</strong> {form.uid}
                    </Typography>
                    <Typography variant="subtitle2" mr={1}>
                        <strong>Current Form Definition ID:</strong>{' '}
                        {form.current_form_definition_id}
                    </Typography>
                    <Typography variant="subtitle2">
                        <strong>You are viewing ID/Version:</strong> {latestFormDefintion?.id}/
                        {latestFormDefintion?.version}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default FormBuilderHeader
