const advancedComponentsConfig = {
    title: '<div>Advanced Components</div>',
    weight: 0,
    components: {
        emailConfigured: {
            title: 'Email',
            key: 'email',
            icon: 'at',
            schema: {
                type: 'email',
                labelPosition: 'bottom',
            },
        },
        urlConfigured: {
            title: 'URL',
            key: 'url',
            icon: 'link',
            schema: {
                type: 'url',
                labelPosition: 'bottom',
            },
        },
        phoneNumberConfigured: {
            title: 'Phone Number',
            key: 'phoneNumber',
            icon: 'phone-square',
            schema: {
                type: 'phoneNumber',
                labelPosition: 'bottom',
            },
        },
        tagsConfigured: {
            title: 'Tags',
            key: 'tags',
            icon: 'tags',
            schema: {
                type: 'tags',
                labelPosition: 'bottom',
            },
        },
        addressConfigured: {
            title: 'Address',
            key: 'address',
            icon: 'home',
            schema: {
                type: 'address',
                labelPosition: 'bottom',
            },
        },
        dateTimeConfigured: {
            title: 'Date / Time',
            key: 'datetime',
            icon: 'calendar',
            schema: {
                type: 'datetime',
                labelPosition: 'bottom',
            },
        },
        dayConfigured: {
            title: 'Day',
            key: 'day',
            icon: 'calendar',
            schema: {
                type: 'day',
            },
        },
        timeConfigured: {
            title: 'Time',
            key: 'time',
            icon: 'clock-o',
            schema: {
                type: 'time',
            },
        },
        currencyConfigured: {
            title: 'Currency',
            key: 'currency',
            icon: 'usd',
            schema: {
                type: 'currency',
                labelPosition: 'bottom',
            },
        },
        surveyConfigured: {
            title: 'Survey',
            key: 'survey',
            icon: 'list',
            schema: {
                type: 'survey',
            },
        },
        signatureConfigured: {
            title: 'Signature',
            key: 'signature',
            icon: 'pencil',
            schema: {
                type: 'signature',
            },
        },
    },
}

export default advancedComponentsConfig
