const checkFileExtension = (path, extension) => {
    if (path === '') return false

    const parts = path.split('.')
    if (parts.length < 2) return false

    const e = parts[parts.length - 1]
    return e.toLowerCase() === extension.toLowerCase()
}

export default checkFileExtension
