import warning from 'assets/warning.svg'
import template from 'lodash/template'

import { progressBarTemplate } from '../templates'

export const formOptions = {
  templates: {
    wizardHeader: {
      form: template(progressBarTemplate),
    },
  },
  i18n: {
    en: {
      error: `<img class="required-fields-info-widget-icon" src="${warning}" alt="missing required fields warning icon"/>Required fields are missing`,
    },
  },
}

export const configureAdditionalCustomExit = onClick => {
  if (document.querySelector('.exit-module')) return
  const progressBar = document.querySelector('.nav-header .progress-bar')
  if (!progressBar) return
  const exitModule = document.createElement('div')
  exitModule.classList.add('exit-module')

  exitModule.addEventListener('click', onClick)
  progressBar.appendChild(exitModule)
}

export const builderName = 'Firefly'

export const confirmationDoneSkipRestart = true
