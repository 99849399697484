/* eslint-disable react/no-array-index-key */
import { Button, Grid } from '@mui/material'
import { ReactElement, useState } from 'react'
import SVG from 'react-inlinesvg'
import { Props as FormRendererProps } from '../../FormRenderer'
import { DefaultSchema } from './default_confirmation_schema'

import checkFileExtension from 'utils/checkExtension'

type Props = Pick<FormRendererProps, 'onNewSubmission' | 'confirmationPage'>

const renderDescriptions = descriptionArray => {
    let htmlContent: ReactElement[] = []
    for (const description of descriptionArray) {
        htmlContent.push(
            <p>
                {description}
                <br />
            </p>,
        )
    }
    return htmlContent
}

const Confirmation = (props: Props) => {
    const { onNewSubmission, confirmationPage } = props
    const schema = confirmationPage ? JSON.parse(confirmationPage) : DefaultSchema

    const [imgLoaded, setImgLoaded] = useState(false)

    const imageLoadedHandler = () => {
        setImgLoaded(true)
    }

    const getImage = () => {
        let src
        try {
            src = require(`./images/${schema.image}`)
        } catch (e) {
            src = ''
        }

        if (checkFileExtension(src, 'svg')) {
            return <SVG src={src} onLoad={imageLoadedHandler} />
        }

        return <img src={src} alt="confirmation img" onLoad={imageLoadedHandler} />
    }
    return (
        <>
            <Grid
                container
                spacing={4}
                alignItems="center"
                direction="column"
                color="var(--primary)"
                className="confirmationPage"
            >
                <Grid item className="confirmationPageItem confirmation-page-image">
                    {getImage()}
                </Grid>
                {imgLoaded && (
                    <>
                        <Grid item className="confirmationPageItem confirmation-page-title">
                            <h1>{schema.title}</h1>
                        </Grid>
                        <Grid item className="confirmationPageItem confirmation-page-desc">
                            {typeof schema.description == 'string' ? (
                                <p>{schema.description}</p>
                            ) : Array.isArray(schema.description) ? (
                                renderDescriptions(schema.description)
                            ) : null}
                            {schema.warning ? (
                                <p
                                    style={{
                                        backgroundColor: '#FFE6E5',
                                        padding: '16px',
                                        paddingLeft: '14px',
                                        color: '#D70000',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {schema.warning}
                                </p>
                            ) : null}
                        </Grid>
                        <Grid item className="confirmationPageButtons">
                            {schema.additionalButtons?.map((button, index) => (
                                <Button
                                    className="btn customConfirmationBtn"
                                    variant="outlined"
                                    onClick={() => window.open(button.src)}
                                    key={index}
                                    data-selector={button.selector}
                                >
                                    {button.label}
                                </Button>
                            ))}
                            {schema.newSubmissionButton.isActive && (
                                <Button
                                    className="btn btn-primary newSubmissionBtn"
                                    variant="outlined"
                                    onClick={onNewSubmission}
                                    data-qa="new-submission"
                                >
                                    {schema.newSubmissionButton.label}
                                </Button>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default Confirmation
