import { ReactComponent } from '@formio/react'
import { Box, Slider } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import settingsForm from './Scale.settingsForm'

import SimpleReactComponent from 'module/SimpleReactComponent'

/**
 * Custom React component
 *
 * 1. The value should be stored in state as "value".
 * 2. When the value changes, call props.onChange(newValue, _flags);
 * 3. Custom components do not send modified flag to Form.onChange.
 * Emit _flag customComponent = true and force the update in Form.
 *
 *
 */
const ScaleCustomComp = ({ component, value, onChange, readOnly }) => {
    const { firstLabel, lastLabel } = component
    const minValue = component.minValue ? parseInt(component.minValue, 10) : 0
    const maxValue = component.maxValue ? parseInt(component.maxValue, 10) : 10

    const [displayValue, setDisplayValue] = useState(value)
    useEffect(() => {
        setDisplayValue(value)
    }, [value])

    const marks = useMemo(() => {
        const array = []
        for (let i = minValue; i <= maxValue; i += 1) {
            const obj = { value: i, label: i.toString() }
            array.push(obj)
        }
        return array
    }, [maxValue, minValue])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    gap: '25px',
                    alignItems: 'center',
                }}
            >
                <Box>{firstLabel}</Box>
                <Slider
                    disabled={!!readOnly}
                    aria-label="Small steps"
                    step={1}
                    min={minValue}
                    max={maxValue}
                    valueLabelDisplay="auto"
                    value={displayValue ?? 0}
                    onChangeCommitted={(_, val) => onChange(val)}
                    onChange={(_, val) => setDisplayValue(val)}
                    marks={marks}
                    sx={{
                        color: 'var(--primary)',
                        flexGrow: 1,
                    }}
                />
                <Box>{lastLabel}</Box>
            </Box>
        </>
    )
}

export default class Scale extends SimpleReactComponent {
    constructor(component, options, data) {
        super(component, options, data)
        this.readOnly = options.readOnly
    }

    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}} Builder info object
     */
    static get builderInfo() {
        return {
            title: 'Scale',
            icon: 'balance-scale',
            documentation: '',
            weight: -10,
            schema: Scale.schema(),
        }
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @returns {*} Custom component schema
     */
    static schema() {
        return ReactComponent.schema({
            type: 'scaleCustomComp',
            label: 'scale',
        })
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm

    renderReact(container, value, setValue) {
        // eslint-disable-next-line react/no-render-return-value
        return ReactDOM.render(
            <ScaleCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={value} // The starting value of the component.
                onChange={setValue} // The onChange event to call when the value changes.
                readOnly={this.readOnly}
            />,
            container,
        )
    }
}
