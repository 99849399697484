import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { map, pipe, split, trim } from 'lodash/fp'

const {
    REACT_APP_DD_APP_ID: applicationId,
    REACT_APP_DD_CLIENT_TOKEN: clientToken,
    REACT_APP_DD_ENV: env,
    REACT_APP_DD_TRACING_ORIGINS: allowedTracingOrigins,
    REACT_APP_VERSION: version,
    REACT_APP_NAME: service,
} = process.env

// https://docs.datadoghq.com/getting_started/site/
const SITE = 'datadoghq.com'

/**
 * @function strToList
 * @param {string}
 * @returns {string[]}
 */
const strToList = pipe(split(','), map(trim))

/**
 * Bootstrap APM Tracing
 *
 * @function bootstrapApmTracing
 * @description Starts up DataDog RUM and tracing. Depending on the configuration the
 * RUM client will decorate requests to allowed origins with x-datadog-trace-id
 * headers, profile the in-browser app and optionally even record sessions.
 * Visit the docs for important configuration options.
 *
 * @docs https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
 */
export const initApmTracing = () => {
    if (applicationId && clientToken) {
        datadogRum.init({
            applicationId,
            clientToken,
            env,
            service,
            version,
            site: SITE,
            sampleRate: 100,
            premiumSampleRate: 50,
            trackInteractions: false,
            defaultPrivacyLevel: 'mask',
            allowedTracingOrigins: strToList(allowedTracingOrigins),
        })
    }
}

export const initDataDogLogs = () => {
    const config = { env, clientToken, service, version }

    if (clientToken) {
        //https://docs.datadoghq.com/logs/log_collection/javascript/
        datadogLogs.init({
            ...config,
            clientToken,
            site: SITE,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            forwardConsoleLogs: 'all',
        })
    }
}
