import cleanDeep from 'clean-deep'
import { Components } from '@formio/react'

import settingsForm from './SelectInsurancePayer.settingsForm'

const SelectFormio = Components.components.select

/**
 * Custom Select insurance payer component created for using to select payers from existing list.
 * This component overwrites a function responsible
 * for displaying a value in HTML readOnly mode.
 * It utilizes a custom "displayValue" field in "data" tab in formBuilder.
 * Make sure to provide appropriate path to the wanted value.
 */
export default class SelectInsurancePayerComp extends SelectFormio {
    constructor(component, options, data) {
        super(component, options, data)
        this.readOnly = options.readOnly
        this.displayValue = 'item.name'
    }

    static get builderInfo() {
        return {
            title: 'Select Insurance Payer',
            icon: 'check-square-o',
            documentation: '',
            weight: -10,
            schema: SelectInsurancePayerComp.schema(),
        }
    }

    static postDataTransform(data) {
        if (Object.keys(data).length === 0) {
            return undefined
        }

        return cleanDeep(data)
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @returns {*} Custom component schema
     */
    static schema() {
        return SelectFormio.schema({
            label: 'Insurance Payer',
            widget: 'choicesjs',
            description: '',
            tooltip: '',
            customClass: '',
            tabindex: '',
            hidden: false,
            hideLabel: false,
            uniqueOptions: false,
            autofocus: false,
            disabled: false,
            tableView: true,
            modalEdit: false,
            multiple: false,
            dataSrc: 'url',
            data: {
                json: '',
                url: !process.env.REACT_APP_BACKEND_URL
                    ? `${process.env.REACT_APP_LOCAL}/insurance/payer/`
                    : `${process.env.REACT_APP_BACKEND_URL}/insurance/payer/`,
                custom: '',
                headers: [
                    {
                        key: '',
                        value: '',
                    },
                ],
                values: [
                    {
                        label: '',
                        value: '',
                    },
                ],
            },
            dataType: '',
            idPath: 'id',
            valueProperty: 'name',
            template: '<span>{{ item.name }}</span>',
            refreshOn: '',
            refreshOnBlur: '',
            clearOnRefresh: false,
            searchEnabled: true,
            selectThreshold: 0.3,
            readOnlyValue: false,
            customOptions: {},
            useExactSearch: false,
            persistent: true,
            protected: false,
            dbIndex: false,
            encrypted: false,
            clearOnHide: true,
            customDefaultValue: '',
            calculateValue: '',
            calculateServer: false,
            allowCalculateOverride: false,
            validateOn: 'change',
            validate: {
                required: false,
                customMessage: '',
                custom: '',
                customPrivate: false,
                json: '',
                strictDateValidation: false,
                multiple: false,
                unique: false,
                onlyAvailableItems: false,
            },
            unique: false,
            errorLabel: '',
            errors: '',
            key: 'select',
            tags: [],
            properties: {},
            conditional: {
                show: null,
                when: null,
                eq: '',
                json: '',
            },
            customConditional: '',
            logic: [],
            attributes: {},
            overlay: {
                style: '',
                page: '',
                left: '',
                top: '',
                width: '',
                height: '',
            },
            type: 'select',
            indexeddb: {
                filter: {},
            },
            selectFields: '',
            searchField: '',
            searchDebounce: 0.3,
            minSearch: 0,
            filter: '',
            limit: 100,
            redrawOn: '',
            labelPosition: 'bottom',
            input: true,
            placeholder: '',
            prefix: '',
            suffix: '',
            dataGridLabel: false,
            showCharCount: false,
            showWordCount: false,
            allowMultipleMasks: false,
            addons: [],
            lazyLoad: true,
            authenticate: false,
            ignoreCache: false,
            fuseOptions: {
                include: 'score',
                threshold: 0.3,
            },
            id: 'evv7k',
            defaultValue: '',
            selectValues: 'results.items',
            disableLimit: false,
            sort: '',
        })
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm

    /*
     * The overwrite of the buggy function in formio
     * Gets an appropriate value in readOnly mode
     * from a custom field "displayValue" in SelectCustomComp definition
     */
    getSingleItemValueForHTMLMode(data) {
        const dataObj = super.getSingleItemValueForHTMLMode(data)
        const path = this.component.displayValue || this.displayValue
        const pathSegments = path.split('.')
        if (pathSegments[0] === 'item') {
            pathSegments.shift()
        }
        let returnValue = dataObj
        pathSegments.forEach(field => {
            returnValue = returnValue?.[field]
        })
        return returnValue || dataObj
    }
}
