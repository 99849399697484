import { Alert, Box, Paper, Snackbar } from '@mui/material'
import { AlertColor } from '@mui/material/Alert/Alert'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Suspense, useCallback, useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

import AppHeader from 'components/AppHeader/AppHeader'
import Loader from 'components/Loader/Loader'

declare module '@mui/material/styles' {
    interface Palette {
        'app-bar': Palette['primary']
    }
    interface PaletteOptions {
        'app-bar': PaletteOptions['primary']
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#008051',
        },
        'app-bar': {
            main: '#1E2233',
        },
    },
})

export const snackbarAlertSeverities: {
    success: AlertColor
    warning: AlertColor
    error: AlertColor
    info: AlertColor
} = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error',
}

type OpenSnackBar = (message: string, severity: AlertColor) => void

type ContextType = {
    openSnackBar: OpenSnackBar
}

export function useSnackBar(): ContextType {
    return useOutletContext<ContextType>()
}

const AppWrapper = (): JSX.Element => {
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarText, setSnackbarText] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>('info')

    const openSnackBar: OpenSnackBar = useCallback(
        (message, severity) => {
            setSnackbarText(message)
            setSnackbarSeverity(severity)
            setSnackbarOpen(true)
        },
        [setSnackbarText, setSnackbarSeverity, setSnackbarOpen],
    )

    const closeSnackBar = useCallback(() => {
        setSnackbarOpen(false)
        setSnackbarSeverity(snackbarAlertSeverities.info)
        setSnackbarText('')
    }, [setSnackbarText, setSnackbarSeverity, setSnackbarOpen])

    const snackBarAutoHideDuration = 3000

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <AppHeader />
                <Paper
                    className="app-wrapper-body"
                    elevation={0}
                    sx={{
                        m: 1,
                        height: 1,
                    }}
                >
                    <Suspense fallback={<Loader />}>
                        <Outlet context={{ openSnackBar }} />
                    </Suspense>
                </Paper>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={snackBarAutoHideDuration}
                onClose={closeSnackBar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={closeSnackBar} severity={snackbarSeverity}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    )
}

export default AppWrapper
