export const DefaultSchema = {
    title: 'Thank You',
    description: 'The form was submitted successfully.',
    image: 'default.svg',
    newSubmissionButton: {
        label: 'Done',
        isActive: true,
    },
    additionalButtons: [],
}
