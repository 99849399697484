import baseEditForm from 'formiojs/components/_classes/component/Component.form'

import omitFormioTabs, { omitTabsBaseConfig } from 'utils/omit-formio-tabs'

const scaleBaseEditForm = (...extend) =>
    baseEditForm(
        [
            {
                key: 'display',
                components: [
                    {
                        key: 'placeholder',
                        ignore: true,
                    },
                    {
                        type: 'textfield',
                        input: true,
                        label: 'First Label',
                        weight: 12,
                        key: 'firstLabel',
                        defaultValue: 'Min',
                    },
                    {
                        type: 'textfield',
                        input: true,
                        label: 'Last Label',
                        weight: 12,
                        key: 'lastLabel',
                        defaultValue: 'Max',
                    },
                    {
                        type: 'textfield',
                        input: true,
                        label: 'Min Value',
                        weight: 12,
                        key: 'minValue',
                        defaultValue: 1,
                    },
                    {
                        type: 'textfield',
                        input: true,
                        label: 'Max Value',
                        weight: 12,
                        key: 'maxValue',
                        defaultValue: 10,
                    },
                ],
            },
            {
                key: 'data',
                components: [],
            },
            {
                key: 'validation',
                components: [],
            },
            {
                key: 'api',
                components: [],
            },
            {
                key: 'conditional',
                components: [],
            },
            {
                key: 'logic',
                components: [],
            },
        ],
        ...extend,
    )

export default omitFormioTabs(scaleBaseEditForm, omitTabsBaseConfig.customComp)
