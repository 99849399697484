import { ReactComponent } from '@formio/react'
import ReactDOM from 'react-dom'

/**
 * @deprecated This is succeeded by ReactComponent.ts, which is a similar implementation
 * but supplies much stronger type information.
 */
export default class SimpleReactComponent extends ReactComponent {
    // eslint-disable-next-line no-useless-constructor
    constructor(component, options, data) {
        super(component, options, data)
    }

    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param element
     * #returns ReactInstance
     */
    attachReact(element) {
        this.#renderReact()
    }

    /**
     * Automatically detach any react components.
     *
     * @param element component parent container
     */
    // eslint-disable-next-line class-methods-use-this
    detachReact(element) {
        if (element) {
            ReactDOM.unmountComponentAtNode(element)
        }
    }

    // eslint-disable-next-line class-methods-use-this
    renderReact(_container, _value, _setValue) {}

    #renderReact() {
        const container = this.refs[`react-${this.id}`]
        if (container) {
            this.renderReact(container, this.dataValue, this.setValue.bind(this))
        }
    }

    setValue(newValue, _flags = {}) {
        const changed = this.updateValue(newValue, {
            customComponent: true,
            ...(_flags ?? {}),
        })
        if (changed) {
            this.#renderReact()
        }
        return changed
    }
}
