import { AppBar, Box, Button, Toolbar } from '@mui/material'
import { useNavigate, Link } from 'react-router-dom'

import { routes } from 'routes'
import { openWindow } from 'utils/browser'
import logo from 'assets/logo.png'

type NavigablePage = {
    label: string
    clickHandler: () => void
    auxClickHandler: () => void
    dataQa: string
    path: string
}

const AppHeader = (): JSX.Element => {
    const navigate = useNavigate()

    const navigateTo = (route: string, wantsNewTab: boolean): void => {
        if (wantsNewTab) {
            openWindow(route, true)
        } else {
            navigate(route)
        }
    }

    const handleFormsNavigation = (wantsNewTab: boolean): void => {
        navigateTo(routes.forms(), wantsNewTab)
    }

    const getPages = (): NavigablePage[] => {
        const formsPage = {
            label: 'Forms',
            clickHandler: () => handleFormsNavigation(false),
            auxClickHandler: () => handleFormsNavigation(true),
            dataQa: 'forms-nav',
            path: '/forms',
        }

        return [formsPage]
    }

    const pages = getPages()

    return (
        // @ts-ignore
        <AppBar color="app-bar" position="static" sx={{ boxShadow: 'none' }}>
            <Toolbar sx={{ height: 74 }}>
                <Link
                    to="/"
                    style={{
                        display: 'block',
                        width: 24,
                        height: 24,
                        marginRight: 24,
                        background: `url(${logo}) center no-repeat`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    }}
                />

                <Box sx={{ flexGrow: 1, display: 'flex', mx: 1 }}>
                    {pages.map(page => (
                        <Button
                            onClick={page.clickHandler}
                            onAuxClick={page.auxClickHandler}
                            key={page.label}
                            sx={{
                                mx: 1,
                                color: 'white',
                                display: 'block',
                                textTransform: 'none',
                                textDecoration:
                                    window.location.pathname === page.path ? 'underline' : 'none',
                            }}
                            data-qa={page.dataQa}
                        >
                            {page.label}
                        </Button>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default AppHeader
