import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import getClient, { ClientOptions } from 'api/api-utils'
import { AxiosInstance } from 'axios'
import { useMutation, useQuery } from 'react-query'
// Try a short delay between render + PDF download
// to make extra sure Form.io components have re-rendered with conditional logic
// TECH-935
export const PDF_WAIT_TIME_MS = 250

// See PatientFormSubmissionUpdateSerializer
export interface FormSubmissionRetrieveResponseData extends FormSubmissionCreateResponseData {
    started_at?: string | null
    completed_at?: string | null
}

// See FormSubmissionSerializer
export interface FormSubmissionCreateResponseData {
    id?: number
    data?: any // decamelized (snake_case) data for backwards-compatibility
    raw_data?: any // raw submission data from this frontend / Form.io. This is what we read from since it should match the Form.io schema
    user?: string
    form?: string
    form_definition?: number | null
    metadata?: any
}

// For backwards-compatibility
// we want to make sure FormSubmission.data continues to contain decamelized data
// This method is meant to mirror the functionality in the backend's `decamelize` fn
// which was used e.g. in `load_zus_form_completion`
const decamelize = (inputStr: string) => {
    const tokens = inputStr.split(/([A-Z0-9][^A-Z0-9]*)/).filter(str => str !== '')
    return tokens.map(el => el.toLowerCase()).join('_')
}

/**
 * Recursively decamelize (convert to snake_case) the keys in an object
 */
export const decamelizeKeys = inputObj => {
    // General approach taken from:
    //github.com/domchristie/humps/blob/f64c539a165f7b2580a0bf12ddb13ce58452abb9/humps.js#L90
    //
    // Is input not an object?
    if (inputObj !== Object(inputObj)) {
        return inputObj
    }
    // Is input a boolean?
    if (toString.call(inputObj) == '[object Boolean]') {
        return inputObj
    }
    const output = {}
    for (var key in inputObj) {
        if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
            output[decamelize(key)] = decamelizeKeys(inputObj[key])
        }
    }
    return output
}

export const useSaveSubmissionMutation = () => {
    const { getAccessTokenSilently } = useAuth0()

    return useMutation({
        mutationFn: async ({
            submissionId,
            data,
        }: {
            submissionId: number
            data: FormSubmissionRetrieveResponseData
        }) => {
            data = {
                ...data,
                data: decamelizeKeys(data.data),
                raw_data: data.data,
            }
            const client = await getClient({ getAccessTokenSilently })
            const response = await client.patch<{ data: FormSubmissionRetrieveResponseData }>(
                `/form/submission/${submissionId}/`,
                data,
            )
            return response.data
        },
    })
}

export const useCreateSubmissionMutation = () => {
    const { getAccessTokenSilently } = useAuth0()

    return useMutation({
        mutationFn: async ({
            formId,
            formDefinitionId,
        }: {
            formId: number
            formDefinitionId: number
        }) => {
            const client = await getClient({ getAccessTokenSilently })
            const response = await client.post<FormSubmissionCreateResponseData>(
                `/form/submission/`,
                {
                    form: formId,
                    form_definition: formDefinitionId,
                    data: {},
                    raw_data: {},
                },
            )
            return response.data
        },
    })
}

export const getFormSubmissionRequest = (client: AxiosInstance, submissionId) => {
    return client.get<FormSubmissionRetrieveResponseData>(`/form/submission/${submissionId}/`)
}

export const useGetFormSubmission = (id: number | null) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery(
        ['formSubmission', id],
        async () => {
            if (!id) {
                console.log('[useGetFormSubmission] query fn called with missing id', id)
                return Promise.resolve(null)
            }
            const client = await getClient({ getAccessTokenSilently })
            return getFormSubmissionRequest(client, id)
        },
        {
            enabled: !!id,
            select: response => {
                if (!response) return null
                return {
                    ...response.data,
                    data: response.data.raw_data,
                }
            },
            staleTime: Infinity,
        },
    )
}

interface PdfExportJob {
    status: 'pending' | 'in-progress' | 'complete' | 'failed'
    url: string | null
}

export const getResponsePDF = async (formSubmissionId: string, options: ClientOptions) => {
    const client = await getClient({
        getAccessTokenSilently: options.getAccessTokenSilently,
    })

    const {
        data: { id: jobId },
    } = await client.post<{ id: number }>('/form/pdf-export-jobs/', {
        form_submission: Number(formSubmissionId),
    })

    await new Promise(resolve => setTimeout(resolve, PDF_WAIT_TIME_MS))

    const job = await pollUntil(
        async () => {
            const { data: job } = await client.get<PdfExportJob>(`/form/pdf-export-jobs/${jobId}/`)
            return job
        },
        job => ['complete', 'failed'].includes(job.status),
    )

    // TODO: Handle exceptions (job failed, retries exhausted)

    // Since this is going to download an S3 presigned URL, we need a different
    // client than the default one for API requests.
    const downloadClient = axios.create()
    const result = await downloadClient.get<Blob>(job.url!, {
        responseType: 'arraybuffer',
    })

    return result
}

/**
 * Run the `poll` function every `intervalMillis`. When the `condition` function
 * given the result of `poll` returns true, resolve the promise with the result.
 * When `maxAttempts` is reached, reject the promise.
 */
async function pollUntil<T>(
    poll: () => Promise<T>,
    condition: (result: T) => boolean,
    intervalMillis = 1000,
    maxAttempts = 10,
) {
    return await new Promise<T>((resolve, reject) => {
        let attempts = 0
        const interval = setInterval(async () => {
            const result = await poll()
            attempts++

            if (condition(result)) {
                resolve(result)
                clearInterval(interval)
            } else if (attempts >= maxAttempts) {
                reject('Maximum attempts exhausted')
                clearInterval(interval)
            }
        }, intervalMillis)
    })
}
