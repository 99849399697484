import { orderBy } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import getClient, { ClientOptions } from 'api/api-utils'
import { useAuth0 } from '@auth0/auth0-react'

export interface FormApiResponse {
    current_form_definition_id: null | number
    id: number
    title: string
    uid: string
    description: string
    pdf_template_key: string | null
    updated_at: string
}

interface FormDefinitionComponent {
    id: string
    key: string
    type: string
    label: string
    title: string
    hidden: boolean
    disabled: boolean
    multiple: boolean
    tooltip: string
    path: string
    components: FormDefinitionComponent[]
    hideLabel: boolean
}

export interface FormDefinitionApiResponse {
    id: number
    updated_at: string
    version: number
    attributes: {
        formDefinition: {
            components: FormDefinitionComponent[]
            confirmationPage: string
            formDefinition: object
            hideProgressBar: boolean
        }
    }
}

export const useGetForms = (params?: { uid?: string; id?: number }) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery(
        ['forms'],
        async () => {
            const client = await getClient({ getAccessTokenSilently })
            return client.get<FormApiResponse[]>('/forms_v2/?vendor=Firefly_V2', {
                params: params || {},
            })
        },
        {
            select: response => {
                return orderBy(response.data, 'updatedAt', 'desc')
            },
        },
    )
}

export const useGetForm = (params: { uid?: string }) => {
    const { data: forms } = useGetForms({ uid: params.uid })
    // UID filtering should guarantee just 1 result
    if (forms) return forms[0]
    if (forms && params.uid) {
        throw new Error(`Form with uid ${params.uid} not found in ${forms}`)
    }
}

export const useGetFormById = (id: number | null) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery(
        ['formById', id],
        async () => {
            const client = await getClient({ getAccessTokenSilently })
            return client.get<FormApiResponse[]>('/forms_v2/', {
                params: { id },
            })
        },
        {
            select: response => {
                return response.data[0]
            },
            enabled: !!id,
        },
    )
}

/**
 * Create a new FormDefinition object, vs. updating and existing one
 * to represent a new version of a Form
 */
export const useCreateFormDefinitionMutation = () => {
    const { getAccessTokenSilently } = useAuth0()

    return useMutation({
        mutationFn: async ({
            formId,
            data,
        }: {
            formId: number
            data: FormDefinitionApiResponse
        }) => {
            const client = await getClient({ getAccessTokenSilently })
            // New record should always have an incremented version number
            return client.post<FormDefinitionApiResponse>('/forms_v2/definitions/', {
                attributes: data.attributes,
                form: formId,
                version: data.version + 1,
            })
        },
    })
}

interface getFormDefinitionRequestParams {
    id?: number
    form__uid?: string
    version?: number
    page_size?: string
    latest?: boolean
    limit?: string
}

export const getFormDefinitionRequest = async (
    clientOptions: ClientOptions,
    params: getFormDefinitionRequestParams,
) => {
    let url = `/forms_v2/definitions/`
    Object.keys(params).forEach(k => (params[k] = params[k].toString()))
    if (params.latest) {
        // Apply a limit of 1. API is configured to order by -id
        delete params.latest
        params.limit = '1'
        params.page_size = '1'
    }
    // Always include a limit
    // for performance reasons and also to ensure we get a paginated response
    // of type {count: number; results: any[]}
    params.limit = params.limit || '50'
    const searchParams = new URLSearchParams(params as Record<string, string>).toString()
    if (searchParams) {
        url += '?' + searchParams
    }
    const client = await getClient(clientOptions)
    return client.get<{ results: FormDefinitionApiResponse[] }>(url)
}

export const useGetFormDefinitions = (options: getFormDefinitionRequestParams) => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery(
        ['formDefinitions', options.form__uid],
        () => getFormDefinitionRequest({ getAccessTokenSilently }, options),
        {
            enabled: !!options.form__uid,
            select: result => {
                return result.data
            },
            staleTime: Infinity,
        },
    )
}

export const useGetFormDefinition = (params: getFormDefinitionRequestParams) => {
    const { getAccessTokenSilently } = useAuth0()
    const { form__uid, id, version } = params

    return useQuery(
        ['formDefinition', id, form__uid, version],
        () => {
            return getFormDefinitionRequest({ getAccessTokenSilently }, params)
        },
        {
            enabled: !!form__uid || !!id,
            select: result => {
                if (result.data.results.length != 1) {
                    console.error('Expected to find 1 FormDefinition', result)
                    return
                }
                return result.data.results[0]
            },
            staleTime: Infinity,
        },
    )
}

export const useUpdateFormSubmissionProgress = (formSubmissionId?: number) => {
    const { getAccessTokenSilently } = useAuth0()

    return useMutation(
        ['updateFormSubmissionProgress', formSubmissionId],
        async ({ pageId, rawData }: { pageId: number; rawData: any }) => {
            const client = await getClient({ getAccessTokenSilently })

            return client.patch(`/form/submission/${formSubmissionId}/update-progress/`, {
                page_id: pageId,
                raw_data: rawData,
            })
        },
    )
}
