import { useEffect, useRef } from 'react'
import Editor, { OnChange, OnMount } from '@monaco-editor/react'

import { monacoEditorTheme } from 'constants/monaco-settings'
import Loader from 'components/Loader/Loader'
import { EditorProps } from '@monaco-editor/react'

type CodeEditorProps = EditorProps & {
    language: string
    inputCode: string
    handleCodeChange: (code?: string) => void
    dataQA?: string
    defaultValue?: string
}

const CodeEditor = ({
    language,
    inputCode,
    handleCodeChange,
    dataQA,
    defaultValue = '',
    ...otherEditorProps
}: CodeEditorProps): JSX.Element => {
    const editorRef = useRef(null)

    useEffect(() => {
        setEditorContent(inputCode)
    }, [inputCode])

    // @ts-ignore
    const setEditorContent = (content: string): void => editorRef?.current?.setValue(content)

    const handleEditorDidMount: OnMount = editor => {
        // @ts-ignore
        editorRef.current = editor
        setEditorContent(inputCode)
    }

    const handleEditorChange: OnChange = (content?: string) => {
        handleCodeChange(content)
    }

    return (
        <Editor
            theme={monacoEditorTheme}
            defaultValue={defaultValue}
            defaultLanguage={language}
            onMount={handleEditorDidMount}
            onChange={handleEditorChange}
            wrapperProps={{
                'data-qa': dataQA,
            }}
            loading={<Loader />}
            {...otherEditorProps}
        />
    )
}

export default CodeEditor
