const addHasValueClassesToFormioControls = (): void => {
    const formControls = document.querySelectorAll<HTMLElement>('.formio-component')
    formControls.forEach(formControl => {
        const fieldWrapper = formControl?.querySelector<HTMLElement>('div')

        const textAreaOrInput = formControl?.querySelector<HTMLInputElement | HTMLTextAreaElement>(
            'input,textarea',
        )
        if (textAreaOrInput) {
            if (textAreaOrInput.value?.length > 0) {
                if (!fieldWrapper?.classList.contains('has-value')) {
                    fieldWrapper?.classList.add('has-value')
                }
            } else {
                fieldWrapper?.classList.remove('has-value')
            }
        }

        const select = formControl.querySelector('select')
        if (select) {
            if (getSelectedValueElement(formControl)?.getAttribute('data-value')) {
                if (!fieldWrapper?.classList.contains('has-value')) {
                    fieldWrapper?.classList.add('has-value')
                }
            } else {
                fieldWrapper?.classList.remove('has-value')
            }
        }
    })
}

export default addHasValueClassesToFormioControls

export const addHasValueEventListenerToBuilderDialogFormioControls = (): void => {
    const formControls = document.querySelectorAll<HTMLElement>(
        '.component-preview .formio-component',
    )
    formControls.forEach(formControl => {
        const fieldWrapper = formControl?.querySelector<HTMLElement>('div')
        const textAreaOrInput = formControl?.querySelector<HTMLInputElement | HTMLTextAreaElement>(
            'input,textarea',
        )

        if (textAreaOrInput) {
            textAreaOrInput?.addEventListener('change', event => {
                if (textAreaOrInput?.value?.length > 0) {
                    if (!fieldWrapper?.classList.contains('has-value')) {
                        fieldWrapper?.classList.add('has-value')
                    }
                } else {
                    fieldWrapper?.classList.remove('has-value')
                }
            })
        }

        const select = formControl.querySelector('select')
        if (select) {
            select?.addEventListener('change', () => {
                if (getSelectedValueElement(formControl)?.getAttribute('data-value')) {
                    if (!fieldWrapper?.classList.contains('has-value')) {
                        fieldWrapper?.classList.add('has-value')
                    }
                } else {
                    fieldWrapper?.classList.remove('has-value')
                }
            })
        }
    })
}

const getSelectedValueElement = (formControl: HTMLElement): HTMLElement | null =>
    formControl.querySelector('.choices__list .is-selected')
