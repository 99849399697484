import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios'

import { authInPatientMode, getAccessToken } from 'utils/auth'

export type ErrorResponse = {
    response: {
        data: {
            message: string
        }
    }
}

export type ClientOptions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAccessTokenSilently: any
    responseType?: ResponseType
}

const getClient = async (options: ClientOptions): Promise<AxiosInstance> => {
    const accessToken = await getAccessToken(options.getAccessTokenSilently)
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        ...(authInPatientMode() ? { 'lucian-user-tenant': 'fireflyhealth' } : {}),
    }
    const axiosRequestConfig: AxiosRequestConfig = {
        headers,
        baseURL: process.env.REACT_APP_BACKEND_URL,
    }
    if (options.responseType) {
        axiosRequestConfig.responseType = options.responseType
    }
    return axios.create(axiosRequestConfig)
}

export default getClient
