import _Date from './components/Date'
import Datetime from './components/Datetime'
import Scale from './components/Scale'
import SelectCustomComp from './components/SelectCustom'
import SelectInsurancePayerComp from './components/SelectInsurancePayer'
import Upload from './components/Upload'

/**
 * This is a "Module", as defined in form.io's documentation:
 * https://help.form.io/developers/modules/
 * For use in early initialization of the form.io library via Formio.use(module).
 *
 * This is the home for all custom components, form templates, validation, etc.
 */
export const module = {
    components: {
        /**
         * Begin basic component overrides
        /**
         * Begin custom components
         * TODO:
         * - See if any custom components are candidates to override defaults, as opposed to just adding new ones
         */
        dateCustomComp: _Date,
        datetimeCustomComp: Datetime,
        selectCustomComp: SelectCustomComp,
        scaleCustomComp: Scale,
        uploadCustomComp: Upload,
        selectInsurancePayerComp: SelectInsurancePayerComp,
    },
}
