import React from 'react'
import { useLocation } from 'react-router-dom'

const useQuery = defaults => {
    const { search } = useLocation()

    return React.useMemo(
        () => ({
            ...defaults,
            ...Object.fromEntries(new URLSearchParams(search)),
        }),
        [defaults, search],
    )
}

// Alias with a better name
// TODO: remove all uses of useQuery
export const useUrlQueryParams = useQuery

export default useQuery
