const basicComponentsConfig = {
    title: '<div>Basic Components</div>',
    default: true,
    weight: 0,
    components: {
        textfieldConfigured: {
            title: 'Text Field',
            key: 'textfield',
            icon: 'terminal',
            schema: {
                type: 'textfield',
                labelPosition: 'bottom',
            },
        },
        textareaConfigured: {
            title: 'Text Area',
            key: 'textarea',
            icon: 'font',
            schema: {
                type: 'textarea',
                labelPosition: 'bottom',
            },
        },
        numberConfigured: {
            title: 'Number',
            key: 'number',
            icon: 'hashtag',
            schema: {
                type: 'number',
                labelPosition: 'bottom',
            },
        },
        passwordConfigured: {
            title: 'Password',
            key: 'password',
            icon: 'asterisk',
            schema: {
                type: 'password',
                labelPosition: 'bottom',
            },
        },
        checkboxConfigured: {
            title: 'Checkbox',
            key: 'checkbox',
            icon: 'check-square',
            schema: {
                type: 'checkbox',
            },
        },
        selectboxesConfigured: {
            title: 'Select Boxes',
            key: 'selectboxes',
            icon: 'plus-square',
            schema: {
                type: 'selectboxes',
            },
        },
        selectConfigured: {
            title: 'Select',
            key: 'select',
            icon: 'th-list',
            schema: {
                type: 'select',
                labelPosition: 'bottom',
            },
        },
        radioConfigured: {
            title: 'Radio',
            key: 'radio',
            icon: 'dot-circle-o',
            schema: {
                type: 'radio',
            },
        },
        buttonConfigured: {
            title: 'Button',
            key: 'button',
            icon: 'stop',
            schema: {
                type: 'button',
            },
        },
    },
}

export default basicComponentsConfig
