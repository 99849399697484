import { createSearchParams } from 'react-router-dom'

// These routes should be restricted to providers authenticating with Auth0
export const adminRoutes = {
    forms: '/forms',
    formBuilder: '/formBuilder',
    preview: '/preview',
}

export const routes = {
    root: (extra?) => buildRouteUrl('/', extra),
    formBuilder: (formId, extra?) => buildRouteUrl(`${adminRoutes.formBuilder}/${formId}`, extra),
    forms: (extra?) => buildRouteUrl(adminRoutes.forms, extra),
    preview: (formUid?, extra?) => buildRouteUrl(`/preview${formUid ? `/${formUid}` : ''}`, extra),
    response: (formUid, extra?) => buildRouteUrl(`/${formUid}`, extra),
    responseView: (formId, id, page?, extra?) =>
        buildRouteUrl(`/${formId}/responses/${id}`, {
            ...(extra ?? {}),
            hash: page && `page-${page}`,
        }),
    responseEdit: (formId, formSubmissionId, page?, extra?) =>
        buildRouteUrl(`/${formId}/responses/${formSubmissionId}/edit`, {
            ...(extra ?? {}),
            hash: page && `page-${page}`,
        }),
}

export const buildRouteUrl = (path, extra?) => {
    let fullPath = path
    if (extra) {
        if (extra.query) {
            fullPath += `?${createSearchParams(extra.query)}`
        }
        if (extra.hash) {
            fullPath += `#${extra.hash}`
        }
    }
    return fullPath
}
