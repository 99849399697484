import { Form } from '@formio/react'
import PropTypes from 'prop-types'

Form.propTypes.options = PropTypes.shape({
    readOnly: PropTypes.bool,
    noAlerts: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    i18n: PropTypes.object,
    template: PropTypes.string,
    saveDraft: PropTypes.bool,
})

export default Form
