import { ReactComponent } from '@formio/react'
import { Button } from '@mui/material'
import { useCallback, useState } from 'react'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ReactDOM from 'react-dom'

import settingsForm from './Upload.settingsForm'

import SimpleReactComponent from 'module/SimpleReactComponent'

const blankImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

const convertBase64 = file =>
    new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result)
        }
        fileReader.onerror = error => {
            reject(error)
        }
    })

/**
 * Custom React component
 *
 * 1. The value should be stored in state as "value".
 * 2. When the value changes, call props.onChange(newValue, _flags);
 * 3. Custom components do not send modified flag to Form.onChange.
 * Emit _flag customComponent = true and force the update in Form.
 *
 *
 */
const UploadCustomComp = ({ value, onChange, readOnly }) => {
    const [previewFailed, setPreviewFailed] = useState(false)
    const [loadedFile, setLoadedFile] = useState(null)

    const uploadHandler = useCallback(
        async e => {
            const file = e.target.files[0]
            if (!file) {
                return
            }

            setLoadedFile(file)
            const base64File = await convertBase64(file)
            onChange(base64File)
        },
        [onChange],
    )

    const getButton = () =>
        !readOnly && (
            <>
                <Button
                    variant="contained"
                    component="label"
                    className="upload-button"
                    sx={{
                        backgroundColor: 'var(--primary)',
                        '&:hover': {
                            backgroundColor: 'var(--primary)',
                        },
                    }}
                >
                    Upload File
                    <input type="file" hidden onChange={uploadHandler} />
                </Button>

                <br />
                <br />
            </>
        )

    return (
        <>
            {getButton()}
            <img
                src={value || blankImage}
                onError={() => setPreviewFailed(true)}
                onLoad={() => setPreviewFailed(false)}
                alt="img"
                style={{
                    maxHeight: '300px',
                    ...(previewFailed ? { display: 'none' } : {}),
                }}
            />
            {previewFailed && (
                <span>
                    <AttachFileIcon />
                    {loadedFile?.name}
                </span>
            )}
        </>
    )
}

export default class Upload extends SimpleReactComponent {
    constructor(component, options, data) {
        super(component, options, data)
        this.readOnly = options.readOnly
    }

    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}} Builder info object
     */
    static get builderInfo() {
        return {
            title: 'Upload',
            icon: 'upload',
            documentation: '',
            weight: -10,
            schema: Upload.schema(),
        }
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @returns {*} Custom component schema
     */
    static schema() {
        return ReactComponent.schema({
            type: 'uploadCustomComp',
            label: 'upload',
        })
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm

    renderReact(container, value, setValue) {
        // eslint-disable-next-line react/no-render-return-value
        return ReactDOM.render(
            <UploadCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={value} // The starting value of the component.
                onChange={setValue} // The onChange event to call when the value changes.
                readOnly={this.readOnly}
            />,
            container,
        )
    }
}
