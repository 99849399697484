import { Components } from '@formio/react'
import InfoIcon from '@mui/icons-material/Info'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as ReactDOM from 'react-dom'
import { useParams } from 'react-router-dom'

import FormEdit from '../../components/FormEdit/FormEdit'
import Loader from '../../components/Loader/Loader'

import EditJsonModal from './components/EditJsonModal/EditJsonModal'
import advancedComponentsConfig from './formioFormBuilderConfigs/advancedComponentsConfig'
import basicComponentsConfig from './formioFormBuilderConfigs/basicComponentsConfig'
import componentEditDialogConfig from './formioFormBuilderConfigs/componentEditDialogConfig'

import {
    useGetForm,
    FormDefinitionApiResponse,
    useCreateFormDefinitionMutation,
    useGetFormDefinitions,
} from 'api/api'
import addHasValueClassesToFormioControls, {
    addHasValueEventListenerToBuilderDialogFormioControls,
} from 'components/FormRenderer/components/InputLabel/InputLabel'
import FormBuilderHeader from 'pages/FormBuilder/components/FormBuilderHeader/FormBuilderHeader'
import SaveFormModal from 'pages/FormBuilder/components/SaveFormModal/SaveFormModal'
import { routes } from 'routes'
import { getFormIoInstance, openWindow } from 'utils/browser'
import { addFormBuilderScriptToDocument, customScriptUrl } from 'utils/formConfig'
import omitFormioTabs, { omitTabsBaseConfig } from 'utils/omit-formio-tabs'

import './style.scss'
import { ClassWithEditForm } from 'formiojs/types'
import { snackbarAlertSeverities, useSnackBar } from 'components/AppWrapper/AppWrapper'

// Configuring the edit menus that appear on formio components in builder
Object.values(Components.components).forEach(component => {
    // TODO: is there a way to use Formio types to better identify the components
    // which should have editForm defined?
    // eslint-disable-next-line no-prototype-builtins
    if (component.hasOwnProperty('editForm')) {
        ;(component as ClassWithEditForm<any>).editForm = omitFormioTabs(
            (component as ClassWithEditForm<any>).editForm,
            omitTabsBaseConfig.formioComp.HideTabsConfig,
        )
    }
})

/**
 * Loads a Form and its recent Form Definitions
 * Allows editing the form (which creates a new FormDefinition object)
 */
const FormBuilder = () => {
    const { formUid } = useParams()
    const form = useGetForm({ uid: formUid })
    // The list of all (paginated) definitions, so we can allow viewing older versions
    const { data: allFormDefinitions } = useGetFormDefinitions({
        form__uid: formUid,
    })
    // Local copy of all definitions, so we can avoid re-fetching all definitions after a save
    const [cachedAllFormDefinitions, setCachedAllFormDefinitions] = useState<
        FormDefinitionApiResponse[]
    >([])
    // The latest form definition is the one we should be building off of by default
    const latestFormDefinition = cachedAllFormDefinitions ? cachedAllFormDefinitions[0] : null
    // Local version of the edits we want to make
    const [editedFormDefinition, setEditedFormDefinition] =
        useState<null | FormDefinitionApiResponse>(null)
    const {
        data: mutateFormDefinitionData,
        mutate: mutateFormDefinition,
        error: mutateFormDefinitionError,
    } = useCreateFormDefinitionMutation()
    const { openSnackBar } = useSnackBar()

    const [previewWindow, setPreviewWindow] = useState<null | Window>(null)

    const [openFormSave, setOpenFormSave] = useState(false)
    const [openJsonEditor, setOpenJsonEditor] = useState(false)

    const scriptUrl = useMemo(() => customScriptUrl(), [])
    const formDefinition = editedFormDefinition || latestFormDefinition

    useMemo(() => {
        addFormBuilderScriptToDocument(scriptUrl)
    }, [scriptUrl])

    // Add the info icon
    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (document.querySelector('.components-info')) return
            const groupTitle = document.querySelector('.zus-components-title')
            if (!groupTitle) return
            const iconWrap = document.createElement('span')
            iconWrap.classList.add('components-info')
            groupTitle.appendChild(iconWrap)
            const infoIcon = (
                <Tooltip title="Custom form.io components prepared by Zus. Click the icon to learn more about them.">
                    <IconButton
                        onClick={() => {
                            window.open(
                                'https://docs.zushealth.com/docs/custom-components',
                                '_blank',
                                'noopener',
                            )
                        }}
                        data-qa="components-info"
                    >
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            )
            ReactDOM.render(infoIcon, iconWrap)
        })

        observer.observe(document.body, { childList: true, subtree: true })
    }, [])

    // Register Formio instance callbacks
    const formioForm = getFormIoInstance()
    useEffect(() => {
        if (formioForm) {
            formioForm?.on('change', addHasValueClassesToFormioControls)
        }

        return () => {
            formioForm?.off('change', addHasValueClassesToFormioControls)
        }
    }, [formioForm])

    const handleSaveForm = () => {
        setOpenFormSave(true)
    }

    const handleSubmit = definition => {
        setEditedFormDefinition(definition)
        setOpenJsonEditor(false)
    }

    const handleSaveToDatabase = async () => {
        if (!form || !editedFormDefinition) {
            return
        }

        await mutateFormDefinition({
            formId: form.id,
            data: editedFormDefinition,
        })
    }

    useEffect(() => {
        if (allFormDefinitions) {
            setCachedAllFormDefinitions(allFormDefinitions.results)
        }
    }, [allFormDefinitions])

    useEffect(() => {
        if (mutateFormDefinitionData) {
            openSnackBar('Saved the form', snackbarAlertSeverities.success)
            setEditedFormDefinition(mutateFormDefinitionData.data)
            setOpenFormSave(false)
            setCachedAllFormDefinitions(current => [mutateFormDefinitionData.data, ...current])
        }
    }, [mutateFormDefinitionData])

    useEffect(() => {
        if (mutateFormDefinitionError) {
            console.error(mutateFormDefinitionError)
            openSnackBar('Failed to save the form', snackbarAlertSeverities.error)
        }
    }, [mutateFormDefinitionError])

    const updatePreview = useCallback(() => {
        if (!previewWindow) {
            return
        }

        previewWindow.postMessage({
            type: 'previewData',
            data: {
                form: formDefinition,
                confirmationPage: formDefinition?.attributes?.formDefinition?.confirmationPage,
            },
        })
    }, [formDefinition, previewWindow])

    const handlePreviewMessages = useCallback(
        event => {
            if (event.source !== previewWindow || event.origin !== window.origin) {
                return
            }

            if (event.data?.type === 'requestPreviewData') {
                updatePreview()
            }
        },
        [previewWindow, updatePreview],
    )

    useEffect(() => {
        window.addEventListener('message', handlePreviewMessages)
        return () => {
            window.removeEventListener('message', handlePreviewMessages)
        }
    }, [handlePreviewMessages])

    const handlePreviewButton = () => {
        if (form) {
            setPreviewWindow(openWindow(routes.preview(form.uid)))
        }
    }

    if (!form || !formDefinition) {
        return <Loader />
    }

    return (
        <Box className="form-builder">
            <FormBuilderHeader
                form={form}
                onOpenDefinitionEditor={() => setOpenJsonEditor(true)}
                onSave={handleSaveForm}
                onPreview={handlePreviewButton}
                latestFormDefintion={latestFormDefinition}
                builderFormDefinition={formDefinition}
            />
            <Box sx={{ m: 1, mt: 2 }}>
                <FormEdit
                    formDefinition={formDefinition}
                    onChange={f => {
                        setEditedFormDefinition(f)
                        addHasValueEventListenerToBuilderDialogFormioControls()
                    }}
                    options={{
                        builder: {
                            basic: false,
                            advanced: false,
                            configuredBasic: basicComponentsConfig,
                            custom: {
                                title: '<div class="zus-components-title">Zus Components</div>',
                                components: {
                                    conditionsCustomComp: true,
                                    chronicConditionsCustomComp: true,
                                    activeMedicationsCustomComp: true,
                                    scaleCustomComp: true,
                                    uploadCustomComp: true,
                                    dateCustomComp: true,
                                    datetimeCustomComp: true,
                                },
                            },
                            customFireflyComps: {
                                title: '<div class="firefly-components-title">Firefly Components</div>',
                                components: {
                                    selectInsurancePayerComp: true,
                                },
                            },
                            configuredAdvanced: advancedComponentsConfig,
                            premium: false,
                        },
                        noNewEdit: true,
                        editForm: componentEditDialogConfig,
                    }}
                />
                <EditJsonModal
                    open={openJsonEditor}
                    onCancel={() => setOpenJsonEditor(false)}
                    defaultDefinition={formDefinition}
                    onSubmit={handleSubmit}
                />
                <SaveFormModal
                    open={openFormSave}
                    generatedSchema={formDefinition}
                    onCancel={() => setOpenFormSave(false)}
                    onSaveToDatabase={handleSaveToDatabase}
                />
            </Box>
        </Box>
    )
}

export default FormBuilder
