import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import download from 'downloadjs'

import ViewResponseButtons from './components/ViewResponseButtons/ViewResponseButtons'
import {
    useGetFormDefinition,
    useGetFormSubmission,
    getResponsePDF,
    PDF_WAIT_TIME_MS,
} from 'api/api'
import FormRenderer from 'components/FormRenderer/FormRenderer'
import { customOptions, customScriptUrl } from 'utils/formConfig'
import { useAuth0 } from '@auth0/auth0-react'

import useQueryParams from 'utils/query'
import logo from 'assets/logo.png'

import './view-response.scss'
import { useQuery } from 'react-query'
import getClient from 'api/api-utils'
import { queryClient } from 'index'

const getTimeZoneAbbreviation = () =>
    new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
const formatDateTimeFull = (dateTime: string) =>
    new Date(dateTime).toLocaleString([], { hour12: true }) + ' ' + getTimeZoneAbbreviation()

const useGetDemographics = userId => {
    const { getAccessTokenSilently } = useAuth0()

    return useQuery(
        ['getDemographics', userId],
        async () => {
            const client = await getClient({ getAccessTokenSilently })
            return client.get<{ first_name: string; last_name: string; dob: string }>(
                `/user/${userId}/demographics`,
            )
        },
        { enabled: !!userId, staleTime: Infinity },
    )
}

const ViewResponse = () => {
    const { getAccessTokenSilently } = useAuth0()
    const { id } = useParams()
    const { renderMode, flatten } = useQueryParams({
        renderMode: 'html',
        flatten: 'true',
    })
    const options = useMemo(() => customOptions(), [])
    const script = useMemo(() => customScriptUrl(), [])
    const { data: formSubmission, isLoading: isFormSubmissionLoading } = useGetFormSubmission(
        id ? parseInt(id) : null,
    )
    const { data: formDefinition, isLoading: isFormDefinitionLoading } = useGetFormDefinition({
        id: formSubmission?.form_definition || undefined,
    })
    const { data: demographicsData, isLoading: isDemographicsDataLoading } = useGetDemographics(
        formSubmission?.user!,
    )
    const isLoading =
        isFormSubmissionLoading || isFormDefinitionLoading || isDemographicsDataLoading
    // Data may be loaded, but we need to wait for the callback from Form.io components
    // to know that the Form is fully ready to show
    const [formRendererReady, setFormRendererReady] = useState(false)

    const saveAsPdf = async () => {
        if (id === undefined) {
            return
        }

        try {
            const response = await getResponsePDF(id, { getAccessTokenSilently })
            download(response.data, `${id}.pdf`)
        } catch (err) {
            console.error(err)
            alert('Failed to download file')
        }
    }

    // A function exposed on the window object by pdf-export's headless browser session
    const pdfExportPostMessage: undefined | ((arg0: any) => void) = (window as any)
        .pdfExportPostMessage

    useEffect(() => {
        // Tell the pdf-export client we're done loading
        // so that we know when it's safe to capture the page content as a PDF
        if (!isLoading && formRendererReady && pdfExportPostMessage instanceof Function) {
            setTimeout(() => {
                console.log('Sending app-ready message')
                pdfExportPostMessage({ type: 'app-ready', formSubmission: formSubmission })
            }, PDF_WAIT_TIME_MS)
        }
    }, [isLoading, formRendererReady, pdfExportPostMessage])

    return (
        <Box className="view-response">
            <ViewResponseButtons
                canDownloadAsPDF={!!formSubmission?.completed_at}
                handleSaveAsPdf={saveAsPdf}
            />
            {isDemographicsDataLoading ? null : (
                <Grid
                    container
                    spacing={2}
                    columns={10}
                    className="form-render-content formio-form"
                    style={{
                        marginBottom: 20,
                        paddingBottom: 10,
                        borderBottom: '1px solid #6b7280',
                    }}
                >
                    <Grid item xs={10}>
                        Patient Name:&nbsp;{demographicsData?.data.first_name}{' '}
                        {demographicsData?.data.last_name}
                    </Grid>
                    <Grid item xs={2}>
                        DOB:&nbsp;{demographicsData?.data.dob}
                    </Grid>
                    {formSubmission?.completed_at ? (
                        <Grid item xs={8}>
                            Completed:&nbsp;{formatDateTimeFull(formSubmission.completed_at)}
                        </Grid>
                    ) : null}
                </Grid>
            )}
            <FormRenderer
                definition={formDefinition?.attributes?.formDefinition}
                submission={formSubmission}
                error={undefined}
                loading={isLoading}
                options={{
                    ...options.formOptions,
                    readOnly: true,
                    renderMode,
                    flatten: flatten === 'true',
                }}
                logo={logo}
                scriptUrl={script}
                onSubmit={() => null}
                submitted={false}
                confirmationPage={null}
                hideProgressBar={false}
                formReady={() => {
                    // For some reason, invalidating the cached Form Submission and bumping the submission data passed to the Form.io renderer
                    // seems to be necessary for computed values to render
                    // From logging I verified that the value of the object is the same before and after the invalidation and fetch
                    // so I think this is not a data issue, and is instead something about the actual cache state or component lifecycle
                    //
                    // Other things I've tried:
                    // 1) The problem is similarly fixed by configuring a refreshInterval on the useQuery hook
                    // (obviously worse than only invalidating/refetching once here)
                    // 2) Updating the cache data directly without a refetch, via setQueryData, does not work
                    // although that would be better because we at least would not have to make an additional network call
                    // 3) Using a separate stateful object, instead of formSubmission, as the `submission` prop to the Form component
                    // and setting that object to the formSubmission within this formReady callback, e.g. `setSubmissionFinal(formSubmission!)`.
                    // That seems like it should have the same effect (updating the submission prop after the Form is ready)
                    // but still it does not work
                    queryClient.invalidateQueries(['formSubmission', formSubmission!.id])
                    setFormRendererReady(true)
                }}
            />
        </Box>
    )
}

export default ViewResponse
