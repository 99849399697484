import setValue from 'set-value'
import getValue from 'get-value'
import brackets2dots from 'brackets2dots'
import { Container, Grid } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Loader from '../Loader/Loader'

import { adjustErrorAlert, scrollToCustomAlert } from './components/alertUtils/alertUtils'
import Confirmation from './components/Confirmation/Confirmation'
import Failure from './components/Failure/Failure'
import Form from './components/FormWrapper/FormWrapper'
import addHasValueClassesToFormioControls from './components/InputLabel/InputLabel'
import { addScriptAndStyleToDocument } from 'utils/formConfig'

import 'components/FormRenderer/style.scss'
import { FormioFormInstance } from 'types/forms.types'
import { FormSubmissionRetrieveResponseData } from 'api/submission-api'

// Event Calllback handler fns may optionally be passed as props
export interface Props extends EventCallbacks {
    definition: any
    options: any
    logo: any
    scriptUrl: string
    debounceTimeout?: number
    loading: boolean
    submitted: boolean
    error?: { message: string; detailedMessage?: string; invalidResponseId?: string }
    page?: number
    onNewSubmission?: (arg0) => void
    onProgress?: (arg0) => void
    confirmationPage: string | null // JSON definition
    hideProgressBar: boolean
    onRestart?: (arg0) => void
    onSubmit: (arg0) => void
    submission?: Pick<FormSubmissionRetrieveResponseData, 'data'> | null
    onFormInstanceRef?: (arg0: React.MutableRefObject<FormioFormInstance | null>) => void
}

const EventNames = [
    'onSubmitDone',
    'onSubmitError',
    'onChange',
    'onError',
    'onRender',
    'onCustomEvent',
    'onPrevPage',
    'onNextPage',
    'formReady',
    'onWizardPageSelected',
    'onFormLoad',
    'onInitialized',
    'onCancel',
    'onAttach',
    'onBuild',
    'onFocus',
    'onBlur',
    'onRequestDone',
    'onLanguageChanged',
    'onSaveDraftBegin',
    'onSaveDraft',
    'onRestoreDraft',
    'onSubmissionDeleted',
    'onRedraw',
    'onComponentChange',
    'onSubmitButton',
    'onEditGridSaveRow',
    'onEditGridDeleteRow',
    'onFileUploadingStart',
    'onFileUploadingEnd',
    'onPagesChanged',
    'onWizardPageClicked',
] as const

type EventName = (typeof EventNames)[number]

type EventCallbacks = {
    [K in EventName]?: (...args) => void
}

/**
 * Interfaces with FormIo Form objects and DOM events e.g. to navigate between pages
 */
const FormRenderer: React.FC<Props> = ({
    submission,
    definition,
    options,
    logo,
    scriptUrl,
    debounceTimeout = 1500,
    loading,
    submitted,
    error,
    page,
    onNewSubmission,
    onProgress,
    confirmationPage,
    hideProgressBar,
    onRestart,
    onSubmit,
    onFormInstanceRef,
    ...rest
}) => {
    const [changes, setChanges] = useState({})
    const [formInstance, setFormInstance] = useState<null | FormioFormInstance>(null)
    const [lastUpdatePromise, setLastUpdatePromise] = useState(Promise.resolve())

    const [submitClicked, setSubmitClicked] = useState<boolean>(false)

    const formInstanceRef = useRef<null | FormioFormInstance>(null)
    const pageRef = useRef<null | number>(null)
    const submitClickedRef = useRef<null | boolean>(null)

    const shouldSkipUpdate = useCallback(() => submitClickedRef.current, [submitClickedRef])

    useEffect(() => addScriptAndStyleToDocument(scriptUrl, hideProgressBar), [scriptUrl])

    useEffect(() => {
        if (onFormInstanceRef) {
            onFormInstanceRef(formInstanceRef)
        }
    }, [formInstanceRef?.current])

    useEffect(() => {
        submitClickedRef.current = submitClicked
        formInstanceRef.current = formInstance
        if (page === undefined || formInstance === null) {
            return
        }
        pageRef.current = page
        formInstance.setPage(page)
    }, [page, formInstance, submitClicked])

    useEffect(() => {
        if (!submitted) setSubmitClicked(false)
    }, [submitted])

    // Track changes in form
    const handleFieldChange = useCallback((submission, _flags, modified) => {
        if (callbacksRef.current.onChange) {
            callbacksRef.current.onChange(submission)
        }

        if (!onProgress || (!_flags.customComponent && !modified)) {
            return
        }

        const { instance } = submission.changed

        // Send entire data grid on change. All rows with all fields.
        // No need for complex patch operations this way
        const path = instance.inDataGrid ? instance.parent.path : instance.path
        const pathSegments = path.split('.')
        setChanges(chngs => ({
            ...chngs,
            [pathSegments[0]]: submission.data[pathSegments[0]],
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // make sure that previous patch has been resolved before ordering a new one
    const updateResponse = useCallback(
        async chngs => {
            await lastUpdatePromise
            if (shouldSkipUpdate()) return
            if (onProgress) await onProgress(chngs)
        },
        [onProgress, lastUpdatePromise, shouldSkipUpdate],
    )

    const debouncedSetChanges = useRef(
        debounce(chngs => {
            // Remove changes that are going to be saved
            setChanges(currentChanges =>
                Object.fromEntries(
                    Object.entries(currentChanges).filter(([key, value]) => chngs[key] !== value),
                ),
            )

            const newUpdatePromise = updateResponse(chngs)
            setLastUpdatePromise(newUpdatePromise)
        }, debounceTimeout),
    ).current

    // Send changes (debounced)
    const saveChanges = useCallback(debouncedSetChanges, [updateResponse])

    useEffect(() => {
        if (Object.keys(changes).length === 0) {
            return
        }
        saveChanges(changes)
    }, [changes, saveChanges])

    const callbacksRef = useRef<EventCallbacks>({})

    const eventProps = useMemo(() => {
        callbacksRef.current = Object.fromEntries(
            Object.entries(rest).filter(([key]) => EventNames.includes(key as EventName)),
        )

        return EventNames.reduce(
            (acc, key) => ({
                ...acc,
                [key]: (...args) => {
                    if (callbacksRef.current[key]) {
                        callbacksRef.current[key]!(...args)
                    }
                },
            }),
            {},
        )
    }, [rest])

    const setUpFloatingLabelListeners = instance => {
        instance.on('change', () => {
            addHasValueClassesToFormioControls()
        })

        instance.on('redraw', () => {
            setTimeout(addHasValueClassesToFormioControls, 0)
        })

        instance.on('nextPage', () => {
            addHasValueClassesToFormioControls()
        })

        instance.on('prevPage', () => {
            addHasValueClassesToFormioControls()
        })

        instance.on('wizardPageSelected', () => {
            addHasValueClassesToFormioControls()
        })
    }

    const handleFormReady = (instance: FormioFormInstance) => {
        // Cancel function is hooked because there is no event for detecting pressing cancel button
        const originalCancel = instance.cancel
        // eslint-disable-next-line no-param-reassign
        instance.cancel = async (...args) => {
            if (!callbacksRef.current.onCancel) {
                return
            }

            const result = await originalCancel.apply(instance, args)
            if (result !== undefined) {
                callbacksRef.current.onCancel()
            }

            return result
        }

        setUpFloatingLabelListeners(instance)

        setFormInstance(instance)
        if (callbacksRef.current.formReady) {
            callbacksRef.current.formReady(instance)
        }
    }

    const handleNextPage = ({ page: p, submission }) => {
        if (callbacksRef.current.onNextPage) {
            callbacksRef.current.onNextPage(p, submission)
            formInstanceRef.current?.setPage(p)
        }
    }

    const handlePrevPage = ({ page: p, submission }) => {
        if (callbacksRef.current.onPrevPage) {
            callbacksRef.current.onPrevPage(p, submission)
            formInstanceRef.current?.setPage(p)
        }
    }

    const submitHandler = useCallback(
        async submission => {
            // Cancel any debounced updates
            // to make sure a pending one doesn't overwrite this final save
            debouncedSetChanges?.cancel()
            formInstanceRef.current?.everyComponent(component => {
                if (!component.constructor.postDataTransform) {
                    return
                }

                const dotsPath = brackets2dots(component.path)
                const oldValue = getValue(submission.data, dotsPath)
                if (oldValue === undefined) {
                    return
                }

                const newValue = component.constructor.postDataTransform(oldValue)
                setValue(submission.data, dotsPath, newValue)
            })

            setSubmitClicked(true)
            await lastUpdatePromise
            onSubmit(submission)
        },
        [lastUpdatePromise, onSubmit],
    )

    const handleError = useCallback(errorList => {
        adjustErrorAlert()
    }, [])

    // We need to rerender state on next click to show 'required fields are missing' widget
    const addOnNextClickListener = () => {
        const nextList = document.getElementsByClassName(
            'btn-wizard-nav-next',
        ) as HTMLCollectionOf<HTMLButtonElement>
        if (nextList.length === 0) return
        if (nextList[0].onclick != null) return

        nextList[0].onclick = scrollToCustomAlert
    }

    // We need to rerender state on submit click to show 'required fields are missing' widget
    const addOnSubmitClickListener = () => {
        const submitList = document.getElementsByClassName(
            'btn-wizard-nav-submit',
        ) as HTMLCollectionOf<HTMLButtonElement>
        if (submitList.length === 0) return
        if (submitList[0].onclick != null) return

        submitList[0].onclick = scrollToCustomAlert
    }

    const getContent = () => {
        if (error)
            return <Failure {...error} onClose={onRestart} closeMessage="Restart Submission" />
        if (loading) return <Loader />
        if (submitted)
            return (
                <Confirmation
                    onNewSubmission={onNewSubmission}
                    confirmationPage={confirmationPage}
                />
            )
        return (
            <Form
                {...rest}
                {...eventProps}
                submission={submission ? (submission as object) : undefined}
                form={definition}
                options={options}
                onChange={handleFieldChange}
                formReady={handleFormReady}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
                onError={handleError}
                onSubmit={submitHandler}
            />
        )
    }

    if (error?.invalidResponseId) {
        // if the error was caused by an invalid QR ID, check if it's stored in localStorage
        // to prevent trying to load a non-existent QR forever
        const activeFormsKey = Object.keys(localStorage).find(k => k.startsWith('activeForms-'))
        if (!activeFormsKey) return null
        try {
            const activeForms = JSON.parse(localStorage[activeFormsKey])
            const activeFormId = Object.keys(activeForms).find(
                k => activeForms[k].id === error.invalidResponseId,
            )
            if (!activeFormId) return null
            delete activeForms[activeFormId]
            localStorage[activeFormsKey] = JSON.stringify(activeForms)
            window.location.replace(`/${activeFormId}`)
        } catch (e) {
            console.error('Failed to delete an invalid QR ID', e)
        }
    }

    addOnNextClickListener()
    addOnSubmitClickListener()

    return (
        <>
            <Grid container direction="row" className="header-wrap">
                <Grid item className="logoContainer">
                    {!loading && <img src={logo} className="logo" alt="logo" />}
                </Grid>
            </Grid>
            <Container className="form-render-content">{getContent()}</Container>
        </>
    )
}

export default FormRenderer
