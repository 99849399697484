import baseEditForm from 'formiojs/components/_classes/component/Component.form'

import omitFormioTabs, { omitTabsBaseConfig } from 'utils/omit-formio-tabs'

const dateBaseEditForm = (...extend) =>
    baseEditForm(
        [
            {
                key: 'display',
                components: [
                    {
                        key: 'placeholder',
                        ignore: true,
                    },
                ],
            },
            {
                key: 'data',
                components: [],
            },
            {
                key: 'validation',
                components: [],
            },
            {
                key: 'api',
                components: [],
            },
            {
                key: 'conditional',
                components: [],
            },
            {
                key: 'logic',
                components: [],
            },
        ],
        ...extend,
    )

export default omitFormioTabs(dateBaseEditForm, omitTabsBaseConfig.customComp)
