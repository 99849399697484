const getComponentByKey = (key, json, cache) => {
    let foundComponent = null
    if (cache[key]) foundComponent = cache[key]

    if (!foundComponent) {
        for (const pageComponent of json.attributes.formDefinition.components) {
            for (const component of pageComponent.components || []) {
                if (component.key == key) {
                    cache[key] = component
                    foundComponent = component
                    break
                }
            }
        }
    }

    return [foundComponent, cache]
}

/**
 * Validates Form.io JSON.
 * For example, check that fields referenced in conditional expressions are valid choices for the corresponding fields
 **/
export const validateFormDefinition = (json: any) => {
    let componentsByKey = {}
    const errorsByKey = {}

    const addError = (key: string, error: string) => {
        if (!errorsByKey[key]) {
            errorsByKey[key] = []
        }
        errorsByKey[key].push(error)
    }

    const pageComponents = json.attributes.formDefinition.components
    for (const pageComponent of pageComponents) {
        for (const component of pageComponent.components || []) {
            if (component.conditional?.when) {
                //  A simple conditional
                //  Example:
                //  {"eq": "yes", "json": "", "show": true, "when": "haveSiblings"}
                const keyTested = component.conditional.when
                const valueTested = component.conditional.eq
                // Is this a valid key?
                let testedComponent
                ;[testedComponent, componentsByKey] = getComponentByKey(
                    keyTested,
                    json,
                    componentsByKey,
                )
                if (!testedComponent) {
                    addError(component.key, `Conditional on missing key ${keyTested}`)
                    continue
                }

                if (testedComponent.values) {
                    let testedValueIsPossible = false
                    for (const possibleValue of testedComponent.values) {
                        // "1" == 1, so conditions even with type mismatches might work just because JS is weird
                        // but let's use === in case there are conditions where a type mismatch would cause issues
                        if (possibleValue.value === valueTested) {
                            testedValueIsPossible = true
                            break
                        }
                    }

                    if (!testedValueIsPossible) {
                        addError(
                            component.key,
                            `Conditional on key ${keyTested}` +
                                ` with value ${valueTested} (${typeof valueTested}), which is not an option`,
                        )
                    }
                }
            }
        }
    }
    return errorsByKey
}
