import { scrollToElementBelowHeader } from 'utils/browser'

export const scrollToCustomAlert = (): void => {
    // checking if a formio alert summary appeared
    const customAlert = document.querySelector('#custom-alert-danger')
    if (customAlert) {
        scrollToElementBelowHeader(customAlert)
    }
}

// Form.io prepends the alert outside of our scope.
// To move it under wizardHeader, we need to handle
// adding and removing it manually
export const adjustErrorAlert = (): void => {
    // check if alert exists
    const alertElems = document.querySelectorAll<HTMLElement>('.alert-danger')
    if (alertElems.length === 0) return

    // copy the newest alert and hide it (form.io will delete it)
    const alert = alertElems[0]
    const alertClone = alert.cloneNode(true) as HTMLElement
    alert.style.display = 'none'

    // delete old custom alert and move the new one under progress bar
    alertClone.id = 'custom-alert-danger'
    const oldElem = document.querySelector<HTMLElement>('#custom-alert-danger')
    if (oldElem) oldElem.remove()

    const wizardPage = document.querySelector('.wizard-page')
    if (wizardPage) {
        wizardPage.prepend(alertClone)
    }
}
