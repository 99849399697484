import cleanDeep from 'clean-deep'
import { Components } from '@formio/react'

import settingsForm from './Select.settingsForm'

const SelectFormio = Components.components.select

/**
 * Custom Select component created for the purpose of using it in a DataGrid.
 *
 * Formio currently displays [object Object] as a value of a select
 * when it is in Components.components.DataGrid and of storageType === object.
 *
 * This component overwrites a function responsible
 * for displaying a value in HTML readOnly mode.
 * It utilizes a custom "displayValue" field in "data" tab in formBuilder.
 * Make sure to provide appropriate path to the wanted value.
 */
export default class SelectCustomComp extends SelectFormio {
    constructor(component, options, data) {
        super(component, options, data)
        this.readOnly = options.readOnly
        this.displayValue = 'item.label'
    }

    static get builderInfo() {
        return {
            title: 'selectCustom',
            icon: 'check-square-o',
            documentation: '',
            weight: -10,
            schema: SelectCustomComp.schema(),
        }
    }

    static postDataTransform(data) {
        if (Object.keys(data).length === 0) {
            return undefined
        }

        return cleanDeep(data)
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @returns {*} Custom component schema
     */
    static schema() {
        return SelectFormio.schema({
            tableView: true,
            input: true,
        })
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm

    /*
     * The overwrite of the buggy function in formio
     * Gets an appropriate value in readOnly mode
     * from a custom field "displayValue" in SelectCustomComp definition
     */
    getSingleItemValueForHTMLMode(data) {
        const dataObj = super.getSingleItemValueForHTMLMode(data)
        const path = this.component.displayValue || this.displayValue
        const pathSegments = path.split('.')
        if (pathSegments[0] === 'item') {
            pathSegments.shift()
        }
        let returnValue = dataObj
        pathSegments.forEach(field => {
            returnValue = returnValue?.[field]
        })
        return returnValue || dataObj
    }
}
