import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import AppWrapper from 'components/AppWrapper/AppWrapper'
import Loader from 'components/Loader/Loader'
import FillForm from 'pages/FillForm/FillForm'
import FormBuilder from 'pages/FormBuilder/FormBuilder'
import ViewResponse from 'pages/ViewResponse/ViewResponse'
import { routes } from 'routes'

const FormPreview = lazy(() => import('pages/FormPreview/FormPreview'))
const Forms = lazy(() => import('pages/Forms/Forms'))

type FormsRouterProps = {
    hasAccessToFormBuilder: boolean
}

const FormsRouter: React.FC<FormsRouterProps> = ({ hasAccessToFormBuilder }: FormsRouterProps) => (
    <Router>
        <Routes>
            {hasAccessToFormBuilder && (
                <>
                    <Route path={routes.root()} element={<AppWrapper />}>
                        <Route
                            path={routes.root()}
                            element={<Navigate replace to={routes.forms()} />}
                        />
                        <Route path={routes.forms()} element={<Forms />} />
                        <Route path={routes.formBuilder(':formUid')} element={<FormBuilder />} />
                    </Route>
                </>
            )}
            <Route path={routes.response(':formId')} element={<FillForm />} />
            <Route path={routes.responseEdit(':formId', ':id')} element={<FillForm />} />
            <Route path={routes.responseView(':formId', ':id')} element={<ViewResponse />} />
            <Route
                path={routes.preview(':formUid')}
                element={
                    <Suspense fallback={<Loader />}>
                        <FormPreview />
                    </Suspense>
                }
            />
            <Route
                path={routes.preview()}
                element={
                    <Suspense fallback={<Loader />}>
                        <FormPreview />
                    </Suspense>
                }
            />
        </Routes>
    </Router>
)

export default FormsRouter
