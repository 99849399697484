import ErrorIcon from '@mui/icons-material/Error'
import { Button, Grid } from '@mui/material'
import { Props as FormRendererProps } from '../../FormRenderer'

interface Props extends NonNullable<FormRendererProps['error']> {
    onClose?: (arg0) => void
    closeMessage: string
}

const Failure = ({ message, detailedMessage, onClose, closeMessage }: Props) => {
    const getErrorMessage = () => {
        if (process.env.REACT_APP_DISPLAY_ERRORS === 'true') {
            return (
                <>
                    <h2>{message}</h2>
                    <h3>{detailedMessage}</h3>
                </>
            )
        }
        return <h2>There was an error rendering your form, please contact support</h2>
    }

    return (
        <>
            <Grid
                container
                spacing={4}
                alignItems="center"
                direction="column"
                color="var(--danger)"
            >
                <Grid item>
                    <ErrorIcon color="error" style={{ fontSize: '200px' }} />
                </Grid>
                <Grid item>
                    <h1>Error</h1>
                </Grid>
                <Grid item style={{ textAlign: 'center' }}>
                    {getErrorMessage()}
                    {onClose && (
                        <Button
                            style={{ margin: 'auto', display: 'block' }}
                            variant="outlined"
                            onClick={onClose}
                        >
                            {closeMessage}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default Failure
