import { FormBuilder } from '@formio/react/lib/components'
import { Box, Button } from '@mui/material'
import { FormDefinitionApiResponse } from 'api/forms-api'
import Loader from 'components/Loader/Loader'

interface Params {
    formDefinition: FormDefinitionApiResponse
    options: any
    onChange?: (arg0: FormDefinitionApiResponse) => void
}

const FormEdit = ({ formDefinition, options, onChange }: Params) => {
    const data = formDefinition?.attributes?.formDefinition
    let pageComponentsLen = data?.components?.length ?? 0

    const onFormBuilderChange = (
        updates: FormDefinitionApiResponse['attributes']['formDefinition'],
    ) => {
        const newComponents = updates.components
        if (newComponents.length > pageComponentsLen) {
            // a new page has been added, hide the label by default
            newComponents[newComponents.length - 1].hideLabel = true
        }
        pageComponentsLen = newComponents.length
        if (onChange) {
            onChange({
                ...formDefinition,
                attributes: {
                    ...formDefinition.attributes,
                    formDefinition: {
                        ...updates,
                    },
                },
            })
        }
    }

    const handleHideAllPageLabels = value => {
        const toggledComponents = data?.components?.map(c => ({
            ...c,
            hideLabel: value,
        }))
        if (!toggledComponents) return
        if (onChange) {
            onChange({
                ...formDefinition,
                attributes: {
                    ...formDefinition.attributes,
                    formDefinition: {
                        ...formDefinition.attributes.formDefinition,
                        components: toggledComponents,
                    },
                },
            })
        }
    }

    return data ? (
        <>
            <Box sx={{ mb: 1 }}>
                <Button
                    sx={{ mr: 1 }}
                    variant="outlined"
                    data-qa="show-all-page-labels"
                    onClick={() => handleHideAllPageLabels(false)}
                >
                    Show All Page Labels
                </Button>
                <Button
                    variant="outlined"
                    data-qa="hide-all-page-labels"
                    onClick={() => handleHideAllPageLabels(true)}
                >
                    Hide All Page Labels
                </Button>
            </Box>
            <FormBuilder
                key={formDefinition.id}
                form={formDefinition.attributes.formDefinition}
                options={options}
                onChange={onFormBuilderChange}
            />
        </>
    ) : (
        <Loader />
    )
}

export default FormEdit
