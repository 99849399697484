import { Auth0Provider } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'

import FormsRouter from './FormsRouter'
import SecuredApp from './SecuredApp'

import Loader from 'components/Loader/Loader'
import { adminRoutes, routes } from 'routes'
import messageParent, { MESSAGE_TYPE } from 'utils/message'

const useAdminLoginSessionStorageKey = 'useAdminLogin'

export interface FormsUIWindow extends Window {
    userId?: string
    accessToken?: string
    isProvider?: boolean
}

const AuthWrapper = () => {
    // There are 2 ways to authenticate
    // 1. in an embedded view (iframe or WebView) pass an access token, by
    //      a. a message event (setToken)
    //      b. setting the access token directly on the window object
    // 2. you login to the forms UI with Auth0 credentials just like you would login to
    //      any other Firefly web app
    const formsUIWindow = window as FormsUIWindow
    const [accessToken, setAccessToken] = useState(formsUIWindow.accessToken)
    const queryParams = new URLSearchParams(formsUIWindow.location.search)

    // Attempt to identify the user based on the user ID passed from the React Native app
    useEffect(() => {
        try {
            const userId = formsUIWindow.userId
            if (userId) datadogRum.setUser({ id: userId })
        } catch (e) {
            console.error('Error setting user context in Datadog', e)
        }
    }, [])

    useEffect(() => {
        const handler = ({ data }) => {
            if (data.type === 'setToken') {
                try {
                    jwtDecode(data.body.token)
                    formsUIWindow.accessToken = data.body.token
                    formsUIWindow.isProvider = !!data.body.isProvider
                    setAccessToken(formsUIWindow.accessToken)
                } catch (e) {
                    messageParent(MESSAGE_TYPE.INVALID_TOKEN)
                }
            }
        }

        window.addEventListener('message', handler)
        messageParent(MESSAGE_TYPE.READY)

        // clean up
        return () => window.removeEventListener('message', handler)
    }, [])

    const useAdminLogin = () => {
        const { pathname } = window.location

        if (pathname === routes.root()) {
            return true
        }

        if (queryParams.get('useZusLogin')) {
            return true
        }

        return Object.values(adminRoutes).some(subDir => pathname.startsWith(subDir))
    }

    if (useAdminLogin()) {
        sessionStorage.setItem(useAdminLoginSessionStorageKey, 'true')
    }

    const insideIframe = window.self !== window.top
    if (sessionStorage.getItem(useAdminLoginSessionStorageKey) && !insideIframe) {
        return (
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
                redirectUri={window.location.origin}
            >
                <SecuredApp />
            </Auth0Provider>
        )
    }

    if (accessToken) {
        return <FormsRouter hasAccessToFormBuilder={false} />
    }

    return <Loader />
}

export default AuthWrapper
