import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import UploadIcon from '@mui/icons-material/Upload'

import CodeEditor from 'components/CodeEditor/CodeEditor'

const EditJsonModal = ({ open, onCancel, defaultDefinition, onSubmit }) => {
    const [rawJson, setRawJson] = useState(null)
    const [editorInputCode, setEditorInputCode] = useState('')

    useEffect(() => {
        const json = JSON.stringify(defaultDefinition, null, 2)
        setRawJson(json)
        setEditorInputCode(json)
    }, [defaultDefinition])

    const handleSubmit = () => {
        onSubmit(JSON.parse(rawJson))
    }

    const handleLoadFile = e => {
        const fr = new FileReader()
        fr.onload = () => {
            const json = fr.result
            setRawJson(json)
            setEditorInputCode(json)
        }
        fr.readAsText(e.target.files[0])
    }

    const handleCodeChange = code => setRawJson(code || '')

    return (
        <Dialog open={open} fullWidth maxWidth="xl">
            <DialogTitle>Edit form JSON</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Edit the form JSON or load new definition from file
                </DialogContentText>
                <Button
                    aria-label="Copy json to clipboard"
                    onClick={() => navigator.clipboard.writeText(rawJson)}
                    startIcon={<ContentCopyIcon />}
                    sx={{ mr: 1 }}
                    data-qa="copy-to-clipboard"
                >
                    Copy to clipboard
                </Button>
                <Button
                    aria-label="Load from file"
                    startIcon={<UploadIcon />}
                    component="label"
                    data-qa="load-from-file"
                >
                    Load from file
                    <input type="file" accept="application/json" hidden onChange={handleLoadFile} />
                </Button>
                <Box sx={{ height: '80vh' }}>
                    <CodeEditor
                        language="json"
                        inputCode={editorInputCode}
                        handleCodeChange={handleCodeChange}
                        dataQA="json-editor"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancel} data-qa="cancel">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit} data-qa="confirm">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditJsonModal
