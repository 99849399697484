import { useState } from 'react'
import { Box, CircularProgress } from '@mui/material'

interface ViewResponseButtonsProps {
    canDownloadAsPDF: boolean
    handleSaveAsPdf: () => Promise<void>
}

const ViewResponseButtons = ({
    canDownloadAsPDF,
    handleSaveAsPdf,
}: ViewResponseButtonsProps): JSX.Element => {
    const [loading, setLoading] = useState(false)

    return (
        <>
            <div className="response-buttons">
                {canDownloadAsPDF && (
                    <Box sx={{ position: 'relative' }}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-qa="download-pdf"
                            disabled={loading}
                            onClick={async () => {
                                setLoading(true)
                                await handleSaveAsPdf()
                                setLoading(false)
                            }}
                        >
                            Download as PDF
                        </button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                )}
            </div>
        </>
    )
}

export default ViewResponseButtons
