import jwtDecode from 'jwt-decode'

/**
 * If an access token is present on the window
 * it  means that we're in "embedded" mode
 * where a client is loading the form in an iframe or WebView
 */
export const authInEmbeddedMode = () => !!(window as any).accessToken

export const getAccessToken = async getAccessTokenSilently => {
    if (authInEmbeddedMode()) {
        return (window as any).accessToken
    }
    // get an access token from Auth0
    return getAccessTokenSilently()
}

export const decodedEmbeddedJwtToken = () => {
    const accessToken = (window as any).accessToken
    if (!accessToken) return null
    const decoded: { sub: string; iss?: string; user_id?: number } = jwtDecode(accessToken)
    return decoded
}

export const authInPatientMode = () => {
    if (!authInEmbeddedMode()) return false
    // Although the PDF generation flow needs to render the app in embedded mode
    // we don't want to auth those requests as patient requests
    // (because the PDF flow is authed by a system user)
    // For now, use a hack to check the JWT shape to see if this is the case
    // (PDF flow JWT is self-issued by the API and has a different shape than Auth0's)
    const decoded = decodedEmbeddedJwtToken()
    if (!decoded) return false
    // Allow a provider-facing client like Lucian to attempt provider vs. patient auth
    if ((window as any).isProvider) return false
    // Does it look like an Auth0 JWT token?
    // Auth0 tokens have a `https://firefly.health/email` claim, while API self-signed tokens do not.
    return !!decoded['https://firefly.health/email']
}
