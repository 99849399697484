// When the app is rendered within an iframe
// allow it to send events to the container
// e.g. for testing, this allows us to inject an access token
const messageParent = (type, body) => {
    window.parent.postMessage(
        {
            type,
            body,
        },
        '*',
    )
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type,
                body,
            }),
        )
    }
}

export const MESSAGE_TYPE = {
    FORM_SUBMISSION_CANCELED: 'formSubmissionCanceled',
    FORM_SUBMISSION_RESTARTED: 'formSubmissionRestarted',
    FORM_SUBMISSION_RESUMED: 'formSubmissionResumed',
    FORM_SUBMISSION_ACKNOWLEDGED: 'formSubmissionAcknowledged',
    FORM_SUBMISSION_CREATED: 'formSubmissionCreated',
    FORM_SUBMISSION_COMPLETED: 'formSubmissionCompleted',
    FORM_SUBMISSION_UPDATED: 'formSubmissionUpdated',
    READY: 'ready',
    INVALID_TOKEN: 'invalidToken',
}

export default messageParent
