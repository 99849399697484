import { FormioFormInstance } from 'types/forms.types'

export const openWindow = (path, newTab?) => window.open(path, newTab ? '_blank' : undefined)
export const scrollTo = position => document.querySelector('html')!.scrollTo(position)
export const scrollUp = () => scrollTo({ top: 0 })
export const scrollToElementBelowHeader = element => {
    const header = document.querySelector('.header-wrap')!
    const y =
        window.pageYOffset +
        element.getBoundingClientRect().top -
        element.getBoundingClientRect().height -
        header.scrollHeight
    scrollTo({ top: y })
}

export const getFormIoInstance = () => {
    const formioForms = Object.values((window as any)?.Formio?.forms) as null | FormioFormInstance[]
    return formioForms ? formioForms[1] : null
}
