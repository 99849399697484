import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { Formio } from '@formio/react'

import AuthWrapper from './AuthWrapper'
import 'scss/main.scss'
import { initApmTracing, initDataDogLogs } from './utils/datadog'
import { module } from './module'
import { QueryClient, QueryClientProvider } from 'react-query'

Formio.use(module)
initApmTracing()
initDataDogLogs()
export const queryClient = new QueryClient()

ReactDOM.render(
    <React.StrictMode>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
            <AuthWrapper />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)
