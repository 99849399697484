import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'

type SaveFormModalProps = {
    open: boolean
    onCancel: () => void
    generatedSchema: unknown
    onSaveToDatabase: () => void
}

const SaveFormModal = ({
    open,
    onCancel,
    generatedSchema,
    onSaveToDatabase,
}: SaveFormModalProps): JSX.Element => (
    <Dialog open={open}>
        <DialogTitle>Save Form?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please use one of the below options to complete your request
            </DialogContentText>
            <ul>
                <li>You can save the form to database</li>
                <li>
                    You can copy the JSON to clipboard, to review the details in a tool of choice
                </li>
            </ul>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={onCancel} data-qa="cancel">
                Cancel
            </Button>
            <Button
                variant="contained"
                onClick={() => navigator.clipboard.writeText(JSON.stringify(generatedSchema))}
                data-qa="copy-to-clipboard"
            >
                Copy to clipboard
            </Button>
            <Button variant="contained" onClick={onSaveToDatabase} data-qa="confirm">
                Save to database
            </Button>
        </DialogActions>
    </Dialog>
)

export default SaveFormModal
