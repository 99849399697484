const componentEditDialogConfig = {
    address: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    currency: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    email: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    number: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    password: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    phoneNumber: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    select: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
                {
                    key: 'placeholder',
                    ignore: true,
                },
            ],
        },
    ],
    textfield: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    textarea: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    tags: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    url: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
    datetime: [
        {
            key: 'display',
            components: [
                {
                    key: 'labelPosition',
                    ignore: true,
                },
            ],
        },
    ],
}

export default componentEditDialogConfig
