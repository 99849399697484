import { ReactComponent } from '@formio/react'
import { useCallback } from 'react'
import ReactDOM from 'react-dom'

import settingsForm from './Date.settingsForm'

import SimpleReactComponent from 'module/SimpleReactComponent'

/**
 * Custom React component
 *
 * 1. The value should be stored in state as "value".
 * 2. When the value changes, call props.onChange(newValue, _flags);
 * 3. Custom components do not send modified flag to Form.onChange.
 * Emit _flag customComponent = true and force the update in Form.
 *
 *
 */
const DateCustomComp = ({ value, onChange, readOnly, component }) => {
    const changeHandler = useCallback(
        val => {
            onChange(val.target.value, { customComponent: true })
        },
        [onChange],
    )

    return (
        // TODO: this label does not actually have any label text
        // but there is styling associated with the "datepicker-label" class
        // Test and verify this is safe to change
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="datepicker-label">
            <input
                className="datepicker-input"
                type="date"
                value={value || ''}
                onChange={changeHandler}
                readOnly={readOnly}
                disabled={component.disabled}
                data-qa="date-input"
                onKeyDown={e => e.preventDefault()}
                onMouseDown={e => e.preventDefault()}
            />
        </label>
    )
}

export default class _Date extends SimpleReactComponent {
    constructor(component, options, data) {
        super(component, options, data)
        this.readOnly = options.readOnly
    }

    /**
     * This function tells the form builder about your component. Its name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}} Builder info object
     */
    static get builderInfo() {
        return {
            title: 'Date',
            icon: 'calendar',
            documentation: '',
            weight: -10,
            schema: _Date.schema(),
        }
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @returns {*} Custom component schema
     */
    static schema() {
        return ReactComponent.schema({
            type: 'dateCustomComp',
            label: 'date',
        })
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm

    renderReact(container, value, setValue) {
        // eslint-disable-next-line react/no-render-return-value
        return ReactDOM.render(
            <DateCustomComp
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={value} // The starting value of the component.
                onChange={setValue} // The onChange event to call when the value changes.
                readOnly={this.readOnly}
            />,
            container,
        )
    }
}
