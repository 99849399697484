import { Box, CircularProgress } from '@mui/material'

const Loader = (): JSX.Element => (
    <Box
        sx={{
            display: 'flex',
            marginTop: '100px',
            justifyContent: 'center',
        }}
    >
        <CircularProgress size={100} />
    </Box>
)

export default Loader
